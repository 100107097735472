import DocumentationPage from "@components/DocumentationPage";
import ItemCardGrid from "@components/web/items/ItemCardGrid";
import { WebItemsGetResponse } from "@reshopper/web-client";
import { RandomDataGenerator } from "@utils/seeding";
import { generateRandomItem } from "../../pages/documentation/item-card";

export default () => <DocumentationPage>
    <ItemCardGrid
        country="dk"
        fetchItems={async options => {
            const items = new Array<WebItemsGetResponse>();
            const randomUser = await RandomDataGenerator.getRandomUser();
            for(let i=0;i<options.pageSize;i++) {
                items.push(
                    await generateRandomItem(randomUser));
            }
            
            return {
                items,
                totalHits: items.length,
                cursorId: null,
                hasMore: false
            }
        }}
        maximumItemCount={1000}
    />
</DocumentationPage>